(function () {
  'use strict';

  angular
    .module('neo.home.referees.newReferee')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.referees.newReferee', {
        url: '/new',
        templateUrl: 'home/referees/new-referee/new-referee.tpl.html',
        controller: 'NewRefereeCtrl',
        controllerAs: 'vm',
        resolve: {
          entities: function(AclService, Entitats){
            if(!AclService.isAdmin()){
              return Entitats.query().$promise;
            }
            else return undefined;
          },
          licenseTypes: function (LicenseTypes) {
            return LicenseTypes.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          },
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          }
        }
      });
  }
}());
